import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <>
      <div className="title-bar">
        <div className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/portfolio">Portfolio</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
      <div className="bar"></div>
    </>
  )
}

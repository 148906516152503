import React, {useState} from 'react'
import { Typography, TextField, Button, Container } from "@mui/material";
import Axios from "axios";


import Navbar from './Navbar'

export default function Contact() {
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    subject: "",
    message: "",
});
const [message, setMessage] = useState("");
const [complete, setComplete] = useState(false);

const url = "https://rmcmillan.co.uk/php-auth/contact.php";

const handleSubmit = async (event) => {
    event.preventDefault();

    Axios.post(url, {
        email: formData.email,
        name: formData.name,
        subject: formData.subject,
        message: message,
    }).then((response) => {
        console.log(response);
        setComplete(true);

    });
};
const onChangeInput = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
};
const handleChange = (event) => {
    setMessage(event.target.value);
};


if (complete) {
  return (
      <div>
        <Navbar/>
<br/>
          <Typography align="center" variant="h5">
              Your message has been Sent
          </Typography>
          <Typography align="center" variant="h6">
Your Message has been sent. I Will get back to you as soon as I can.                </Typography>
          <div align="center">

             
          </div>
      </div>
  )
} else {
  return (
      <div className="content">
<Navbar/>

          <div className="formContainer">
              <form className="form" onSubmit={handleSubmit}>
                  <Typography align="center" variant="h3">
                      Contact 
                  </Typography>
                  <Typography align="left" variant="h6">
                      The fields marked with * are required
                  </Typography>

                  <TextField
                      placeholder="Name"
                      type="Text"
                      margin="normal"
                      name="name"
                      required
                      fullWidth
                      label="Name"
                      value={formData.name}
                      onChange={onChangeInput}
                  />

                  <TextField
                      placeholder="Email"
                      type="email"
                      margin="normal"
                      name="email"
                      required
                      value={formData.email}
                      onChange={onChangeInput}
                      label="Email"
                  />
                  <TextField
                      placeholder="Subject"
                      type="text"
                      margin="normal"
                      name="subject"
                      required
                      value={formData.subject}
                      onChange={onChangeInput}
                      label="Subject"
                  />

                  <textarea
                      placeholder="Your Message"
                      value={message}
                      onChange={handleChange}
                  ></textarea>
                  <Button variant="contained" type="submit" className="button-contact">
                      Submit
                  </Button>
              </form>
          </div>
          <br />
      </div>
  );
}
}

import { Link } from 'react-router-dom';
import '../App.css';

function Home() {

    return (
        <div className='App'>
            <div className="home-page">
            <style>{'body { background-color: #193c6d; }'}</style>

            <h1>Hi, I'm Ross McMillan</h1>
            <h1>I'm a web developer based near Glasgow</h1>
            <br></br>
            <div className='home-buttons'>
                <Link to="/about"><button>About</button></Link>
                <Link to="/portfolio">
                <button  >Portfolio</button>
                </Link>
                <Link to="/contact">

                <button>Contact</button>
                </Link>
            </div>
        </div>
        </div>
       
    );
}

export default Home;

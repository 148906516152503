import { Typography } from '@mui/material'
import React from 'react'
import Navbar from './Navbar'


export default function About() {
  return (<>
  <Navbar/>
<div className='about'>
<Typography align="left" variant="h5">
    Hi, my name is 

  </Typography>
  <Typography align='left' variant='h2'>
    Ross McMillan
  </Typography>
  <Typography> I build things for the web.</Typography>
  <Typography> I'm a software engineer specializing in building websites and other web based apps.</Typography>

  <button>Check out my previous projects!</button>

  <Typography>About Me </Typography>

  <Typography>Education</Typography>
</div>
  
  </>
  )
}

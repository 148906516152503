import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom'
import { HashRouter, Route, Routes } from "react-router-dom";

import Home from './Components/Home';
import Portfolio from './Components/Portfolio'
import Contact from './Components/Contact';
import About from './Components/About'
import './App.css'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <HashRouter className="App">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="portfolio" element={<Portfolio/>}/>
        <Route path='contact' element={<Contact/>}/>       
         <Route path='about' element={<About/>}/>
      </Routes>
     </HashRouter>
     </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Grid } from "@mui/material";
import Axios from "axios";
import { Card, CardContent, Link, Typography } from "@mui/material";
import "../App.css";

export default function Portfolio() {
  const [portfolios, SetPortfolios] = useState(null);

  useEffect(() => {
    Axios.get("https://rmcmillan.co.uk/php-auth/get-portfolio.php")
      .then((res) => {
        console.log(res.data);
        SetPortfolios(res.data);
      })
      .then((data) => {
        //SetPortfolios(data.Portfolio);
        //console.log(data.Portfolio)
      });
  }, []);

  return (
    <>
      <Navbar />
      <br/>
      <div className="portfolio-page">
        <Typography className="title" variant="h3">
          Portfolio Page
        </Typography>

        <Grid container justifyContent="center" spacing={0}>
          {portfolios &&
            portfolios.map((project) => (
              <Grid key={project.id} item xs={12} sm={6} md={6} lg={6}>
                <a href={project.url}>
                  <div>
                    <img
                      width="100%"
                      height="auto"
                      src={
                        "https://rmcmillan.co.uk/php-auth/images/" +
                        project.image
                      }
                    />
                    <Typography variant="h4">{project.title}</Typography>
                    <Typography variant="body1">{project.text}</Typography>
                  </div>
                </a>
              </Grid>
            ))}
        </Grid>
      </div>
    </>
  );
}
